import { WatcherID, UserID } from "@entropyinternet/alertsboo-helpers";
import { NODE_ENV } from "./env";

const devConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "http://localhost:8080/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `http://localhost:8080/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `http://localhost:8080/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) => `http://localhost:8080/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `http://localhost:8080/v1/watchers/${watcherID}/logs/list`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `http://localhost:8080/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) => `http://localhost:8080/v1/users/${userID}/change-sub`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `http://localhost:8080/v1/billing/${userID}/setup-intent`,
    },
    stripeRemoveCard: {
      url: (userID) => `http://localhost:8080/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyBOuufcHyvxV0Gfkl5gyHCdC5dHeBRjVHc",
    authDomain: "alerts-dev-425506.firebaseapp.com",
    projectId: "alerts-dev-425506",
    storageBucket: "alerts-dev-425506.appspot.com",
    messagingSenderId: "188427361894",
    appId: "1:188427361894:web:04ea55eac879d381d0d7a7",
    measurementId: "G-VTFM1DPZTT"
  },
  STRIPE: {
    customerPortalUrl:
      "https://billing.stripe.com/p/login/test_7sI3cV0eM1WDgvufYY",
    publishKey:
      "pk_test_51McyYLBbKljWimkIR6ulMVmH6tRKMLCQfMEeImPqTrl9pkfT7z3h8aEchJ4rPg31ppRdZEtJ3UXMPCc6h9262Umy00z6Fn1KAz",
  },
};
const stagingConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/${watcherID}/logs/list`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/users/${userID}/change-sub`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/setup-intent`,
    },
    stripeRemoveCard: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyBOuufcHyvxV0Gfkl5gyHCdC5dHeBRjVHc",
    authDomain: "alerts-dev-425506.firebaseapp.com",
    projectId: "alerts-dev-425506",
    storageBucket: "alerts-dev-425506.appspot.com",
    messagingSenderId: "188427361894",
    appId: "1:188427361894:web:04ea55eac879d381d0d7a7",
    measurementId: "G-VTFM1DPZTT"
  },
  STRIPE: {
    customerPortalUrl:
      "https://billing.stripe.com/p/login/test_7sI3cV0eM1WDgvufYY",
    publishKey:
      "pk_test_51McyYLBbKljWimkIR6ulMVmH6tRKMLCQfMEeImPqTrl9pkfT7z3h8aEchJ4rPg31ppRdZEtJ3UXMPCc6h9262Umy00z6Fn1KAz",
  },
};
const prodConfig: ConfigEnv = {
  REST_API: {
    createWatcher: {
      url: "https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/create",
    },
    updateWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/${watcherID}/update`,
    },
    listWatchers: {
      url: `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/list`,
    },
    rotateToken: {
      url: (userID) => `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/users/${userID}/rotate-token`,
    },
    listLogsOfWatcher: {
      url: (watcherID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/watchers/${watcherID}/logs/list`,
    },
    stripeSetupIntent: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/setup-intent`,
    },
    stripePaymentIntent: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/payment-intent`,
    },
    changeSubscription: {
      url: (userID) => `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/users/${userID}/change-sub`,
    },
    stripeRemoveCard: {
      url: (userID) =>
        `https://alertsboo-server-5jmrlhy62q-ts.a.run.app/v1/billing/${userID}/remove-card`,
    },
  },
  FIREBASE: {
    apiKey: "AIzaSyANzBGjA17cQbB8pAVtIXZODvUcm1S8Mt0",
    authDomain: "alerts-425707.firebaseapp.com",
    projectId: "alerts-425707",
    storageBucket: "alerts-425707.appspot.com",
    messagingSenderId: "293972990256",
    appId: "1:293972990256:web:32ad318c0eec28d2c590f5",
    measurementId: "G-LG476KRC7M"
  },
  STRIPE: {
    customerPortalUrl: "https://billing.stripe.com/p/login/fZecQubkxcwOgDuaEE",
    publishKey:
      "pk_live_51McyYLBbKljWimkIVl1fpkwjqXmr3QF2yIgnh93AWMXTYjL52DL7YHjV6wakfE0lNBoD2uzx6OVnAULfGjXl1a9700kbt34b6y",
  },
};

export interface RestApiRateLimit {
  windowMs: number;
  max: number;
}
interface ConfigEnv {
  REST_API: {
    createWatcher: {
      url: string;
    };
    updateWatcher: {
      url: (watcherID: WatcherID) => string;
    };
    listWatchers: {
      url: string;
    };
    rotateToken: {
      url: (userID: UserID) => string;
    };
    listLogsOfWatcher: {
      url: (watcherID: WatcherID) => string;
    };
    stripeSetupIntent: {
      url: (userID: UserID) => string;
    };
    stripeRemoveCard: {
      url: (userID: UserID) => string;
    };
    stripePaymentIntent: {
      url: (userID: UserID) => string;
    };
    changeSubscription: {
      url: (userID: UserID) => string;
    };
  };
  FIREBASE: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  STRIPE: {
    customerPortalUrl: string;
    publishKey: string;
  };
}

export default (() => {
  // @ts-ignore
  if (NODE_ENV === "production") {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
  }

  console.log(`NODE_ENV: ${NODE_ENV}`);
  // @ts-ignore
  if (NODE_ENV === "production") {
    return prodConfig;
    // @ts-ignore
  } else if (NODE_ENV === "staging") {
    return stagingConfig;
  } else if (NODE_ENV === "development") {
    return devConfig;
  }
  return devConfig;
})();

export enum BUILD_ENV_TARGET {
  PRODUCTION = "production",
  STAGING = "staging",
  DEVELOPMENT = "development",
}
