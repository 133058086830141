// WatchersPage.tsx
import React, { useEffect } from "react";
import { useUserStore } from "../../store";
import { getAuth, signOut } from "firebase/auth";
import {
  Avatar,
  Badge,
  Button,
  Input,
  List,
  Result,
  Skeleton,
  Space,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  loadFirestoreWatchers,
  useWatcherStore,
} from "../../store/watchers.store";
import {
  WatcherPlatform,
  WatcherStatus,
  UserID,
  Watcher_Firestore,
} from "@entropyinternet/alertsboo-helpers";
import { BellOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../config.env";

export const PlatformToLogo = {
  [WatcherPlatform.instagram]:
    "https://cdn.iconscout.com/icon/free/png-256/free-instagram-52-151106.png",
  [WatcherPlatform.twitter]:
    "https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.87170709.1707696000&semt=ais",
  [WatcherPlatform.facebook]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.reddit]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.tiktok]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.youtube]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.linkedin]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.twitch]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.pinterest]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.discord]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.google_reviews]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
  [WatcherPlatform.tumblr]:
    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
};

export const WatcherStatusToAntdColor: Record<
  WatcherStatus,
  "processing" | "success" | "default" | "error" | "warning" | undefined
> = {
  [WatcherStatus.pending]: "processing",
  [WatcherStatus.active]: "success",
  [WatcherStatus.awaiting]: "success",
  [WatcherStatus.paused]: "default",
  [WatcherStatus.inactive]: "default",
  [WatcherStatus.banned]: "error",
  [WatcherStatus.problem]: "error",
  [WatcherStatus.insufficient]: "warning",
};

const WatchersPage = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { firebaseUser } = useUserStore((state) => state);
  const { watchers, loading, cursor } = useWatcherStore((state) => state);
  const [searchString, setSearchString] = React.useState("");
  const [deepSearchWatchers, setDeepSearchWatchers] = React.useState<
    Watcher_Firestore[]
  >([]);

  console.log("watchers", watchers);
  console.log("cursor", cursor);

  useEffect(() => {
    if (!searchString) {
      setDeepSearchWatchers([]);
    }
  }, [searchString]);

  const searchBySlug = async (slug: string) => {
    console.log("searchBySlug", slug);
    if (slug && firebaseUser) {
      try {
        const idToken = await firebaseUser.getIdToken();
        const res = await axios.post(
          config.REST_API.listWatchers.url,
          {
            slug,
            limit: 100,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = res.data;
        const watchers = data.data.watchers;
        setDeepSearchWatchers(watchers);
      } catch (e) {
        console.error(e);
        setDeepSearchWatchers([]);
      }
    }
  };

  const filteredWatchers =
    deepSearchWatchers && deepSearchWatchers.length > 0
      ? deepSearchWatchers
      : watchers.filter((watcher) => {
          return (
            watcher.alias.toLowerCase().includes(searchString.toLowerCase()) ||
            watcher.slug.toLowerCase().includes(searchString.toLowerCase())
          );
        });

  const loadMore = async () => {
    if (auth?.currentUser?.uid) {
      await loadFirestoreWatchers(auth.currentUser.uid as UserID, cursor);
    }
  };

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h1>Watchlist</h1>
          <Link to="/watchers/create">
            <Button type="primary">Add New</Button>
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Space.Compact style={{ width: "100%", marginBottom: "32px" }}>
            <Input
              placeholder="Search Watchers"
              allowClear
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <Button onClick={() => searchBySlug(searchString)} type="primary">
              Search
            </Button>
          </Space.Compact>
        </div>
        {watchers.length === 0 ? (
          <div style={{ marginTop: "70px" }}>
            <Result
              icon={<BellOutlined />}
              title="Get started by created a Watcher!"
              extra={
                <Link to="/watchers/create">
                  <Button type="primary">Create</Button>
                </Link>
              }
            />
            {/* <iframe
              width={600 * (560 / 315)}
              height={600}
              src="https://www.youtube.com/embed/Mou-4gB-R4c?si=KcMUNUOMJAoeZA38"
              title="Alerts.boo Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
          </div>
        ) : (
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            loadMore={
              <Button
                disabled={!cursor}
                onClick={loadMore}
                style={{ marginTop: "32px" }}
              >
                Load More
              </Button>
            }
            dataSource={filteredWatchers}
            renderItem={(watcher) => (
              <List.Item
                actions={[
                  <Link to={`/watchers/${watcher.id}`}>
                    <Button>View</Button>
                  </Link>,
                ]}
              >
                <Skeleton avatar title={false} loading={false} active>
                  <List.Item.Meta
                    avatar={
                      <Badge
                        dot
                        status={
                          WatcherStatusToAntdColor[
                            watcher.status as WatcherStatus
                          ]
                        }
                      >
                        <Avatar
                          src={
                            PlatformToLogo[watcher.platform as WatcherPlatform]
                          }
                        />
                      </Badge>
                    }
                    title={
                      <span style={{ textAlign: "left" }}>{watcher.slug}</span>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default WatchersPage;
